import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CgMail } from "react-icons/cg";
import { MdOutlineCall } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Drawer, ButtonToolbar, Button, Placeholder } from "rsuite";
import { TiThMenu } from "react-icons/ti";

function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openWithHeader, setOpenWithHeader] = React.useState(false);

  return (
    <React.Fragment>
      <div>
        <div className="main">
          <div className="top-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-7">
                  <div className="d-flex">
                    <div className="d-flex justify-content-between">
                      <i>
                        <CgMail />
                      </i>
                      <p>Sample@gmai.com</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <i>
                        <MdOutlineCall />
                      </i>
                      <p>+91-1234567890</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <i>
                        <IoMdTime />
                      </i>
                      <p>Mon to Sat : 08.00 AM - 06.00 PM</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="social-links">
                    <ul className="d-flex m-0">
                      <li>
                        <FaFacebookF />
                      </li>
                      <li>
                        <FaInstagram />
                      </li>
                      <li>
                        <FaTwitter />
                      </li>
                      <li>
                        <FaLinkedinIn />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="/images/logo-new.png"
                        style={{
                          maxWidth: "50%",
                          padding: "20px",
                          background: "#fff",
                          borderRadius: "0 0 20px 20px",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-md-9 d-flex justify-content-end">
                  <div className="nav-links">
                    <ul className="m-0">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/project">Projects</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/blog">News & Blogs</Link>
                      </li>
                      <li>
                        <Link to="/gallery">Our Gallery</Link>
                      </li>
                      <li>
                        <Link to="/testimonial">Testimonials</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contacts Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
         <div className="new-m-nav">
         <div className="mobile-headerr">
            <Link>
              <img src="/images/logo.png" style={{ maxWidth: "70%" }} />
            </Link>
            <div>
              <ButtonToolbar>
                <Button onClick={() => setOpenWithHeader(true)}>
                  <TiThMenu />
                </Button>
              </ButtonToolbar>
            </div>
          </div>
         </div>

          <Drawer
            placement="left"
            size={"xs"}
            open={openWithHeader}
            onClose={() => setOpenWithHeader(false)}
          >
            <Drawer.Header>
              <Drawer.Title>
                <img src="/images/logo.png" style={{ maxWidth: "90%" }} />
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <div className="mobile-links">
                <ul className="m-0 p-0">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/project">Projects</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/blog">News & Blogs</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Our Gallery</Link>
                  </li>
                  <li>
                    <Link to="/testimonial">Testimonials</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contacts Us</Link>
                  </li>
                </ul>
              </div>
            </Drawer.Body>
          </Drawer>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Header;
