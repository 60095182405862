import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: `https://demo.oriolinfotech.com:50013/api/v1/`,
  // baseURL: "http://localhost:5008/api/v1/",
});

export const imageUrl = "https://demo.oriolinfotech.com:50013/";

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("Token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("Admin");
      Cookies.remove("Token");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default api;
