import React from "react";
import { FaHome } from "react-icons/fa";
import { MdEmail, MdCall } from "react-icons/md";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <React.Fragment>
      <div className="footersection">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="f-inner" style={{paddingRight:"20px"}}>
              {/* <img src="/images/f-logo.png" style={{maxWidth:"72%"}}/> */}
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form,</p>
              <ul>
                <li><i><FaHome /></i><a href="#">Commercial plot no 3, adjacent to Kriti health care center , sector 63 Gurgaon122011</a></li>
                <li><i><MdEmail /></i><a href="#">Example@gmail.com</a></li>
                {/* <li><i><MdCall /></i><a href="#">+91-1234567890</a></li> */}
                <li><i><MdCall /></i><a href="#">Sagar solanki - 9821969700</a></li>
                <li><i><MdCall /></i><a href="#">Rajiv Sehgal - 9821919274</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <div className="f-inner">
              <h3>Information</h3>
              <ul>
                <li>Contat</li>
                <li>Privacy & Policy</li>
                <li>Terms & Conditions</li>
                <li>FAQs</li>
              </ul>
            </div>
          </div>
          <div className="col-md-2">
          <div className="f-inner">
              <h3>Properties</h3>
              <ul>
                <li>Commercial</li>
                <li>House</li>
                <li>Residential</li>
                <li>Office</li>
                <li>Residential Tower</li>
              </ul>
            </div>
          </div>
          <div className="col-md-2">
          <div className="f-inner">
              <h3>Locations</h3>
              <ul>
                <li>Indore</li>
                <li>Bhopal</li>
                <li>Gwalior</li>
                <li>Delhi</li>
                <li>Mumbai</li>
              </ul>
            </div>
          </div>
          <div className="col-md-2">
          <div className="f-inner">
              <h3>Useful Links</h3>
              <ul>
              <li><Link to="/">Home</Link></li>
                <li><Link to="/project">Projects</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/blog">News & Blogs</Link></li>
                <li><Link to="/gallery">Our Gallery</Link></li>
                <li><Link to="/testimonial">Testimonials</Link></li>
                <li><Link to="/contact">Contacts Us</Link></li>
              </ul>
            </div>
          </div>
         
        </div>
        <div className="copyright">
        Copyright © 2024. All Rights Reserved by <a href="#">Oriol Infotech Solutions</a>
        </div>
      </div>
      
      </div>
    </React.Fragment>
  );
}

export default Footer;
