import React from "react";
import { useQuery } from "react-query"; // Import useQuery from React Query
import Header from "./component/Header";
import Footer from "./component/Footer";
import DOMPurify from "dompurify";
import { Button, Carousel } from "rsuite";
import { useNavigate } from "react-router-dom";
import api, { imageUrl } from "./lib/api"; 

const BlogDetail = () => {
  const navigate = useNavigate();

  // Use useQuery to fetch blog data
  const { data, isLoading, error } = useQuery({
    queryKey: ["fetch-blogs"],
    queryFn: () => api.get("/blog/blog").then((res) => res.data.data),
  });

  // Navigate to the blog details page
  const goToBlogDetails = (id) => {
    navigate(`/blog/${id}`); 
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching blogs: {error.message}</div>; 
  }

  return (
    <div>
      <Header />
      <div className="inner-banner">
        <div className="banner-content">
          <h1>News & Blogs</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" alt="Banner 1" />
          </div>
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
        <h1>News & Blogs</h1>
        <p>
          Being one of the top real estate home builders in Kerala, we bring
          you a new array of apartments, flats, and villas that match your
          requirements and <br /> complement your lifestyle.
        </p>
      </div>

      <div className="blog-section bnew">
        <div className="container">
          <div className="heading">
            <h2 className="text-center">News & Blogs</h2>
          </div>
          <div className="row mt-5">
            {data.length > 0 ? ( // Use the fetched data directly
              data.map((blog) => (
                <div className="col-md-4 mb-5" key={blog.id}>
                  <div className="blog-inner">
                  <img src={`${imageUrl}/images/${blog.image}`} alt={blog.title}/>
                    <h3>{blog.title}</h3>
                    <h5 className="mb-3">{new Date(blog.created_at).toLocaleDateString()}</h5>
                    {/* <div
                      className="col-md-12"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(blog?.content),
                      }}
                    ></div> */}
                    <Button
                      color="cyan"
                      appearance="primary"
                      onClick={() => goToBlogDetails(blog.id)}
                    >
                      View More
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <p>No blogs available</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
