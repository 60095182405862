import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import api, { imageUrl } from "./lib/api"; 
import Header from "./component/Header";
import { useNavigate } from "react-router-dom";
import Footer from "./component/Footer";
import { Button, Carousel } from "rsuite";
import DOMPurify from "dompurify";


const BlogSingleDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const { data, isLoading, error } = useQuery({
    queryKey: ["fetch-blog", id],
    queryFn: () => api.get(`/blog/blog/${id}`),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching blog: {error.message}</div>;
  }
  const goToBlogDetails = (id) => {
    navigate(`/blog/${id}`); 
  };
  const blogs = Array.isArray(data?.data?.data) ? data.data.data : [];
  if (blogs.length === 0) {
    console.log("No blogs found");
    return <div>No blogs available</div>;
  }

  return (
    <div>
         <div>
      <Header />
      <div className="inner-banner">
        <div className="banner-content">
        <h1>Blog Details</h1>
          
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" alt="Banner 1" />
          </div>
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
        <h1>Blog Details</h1>
      </div>

      <div className="blog-section bnew">
        <div className="container">
          <div className="heading">
            {/* <h2 className="text-center">News & Blogs</h2> */}
          </div>
     
          <div className="row mt-5">
        {blogs.map((blog) => (
          <div className="col-md-12 mb-5" key={blog.id}>
            <div className="blog-inner">
             <div className="row">
              <div className="col-md-6">
              <img src={`${imageUrl}/images/${blog.image}`} alt={blog.title} />
             
              </div>
              <div className="col-md-6">
                <div className="blog-inner-details">
                <h3>{blog.title}</h3>
              <h5>{new Date(blog.created_at).toLocaleDateString()}</h5>
              <div
                      className="col-md-12"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(blog?.content),
                      }}
                    ></div>
                </div>
              </div>
             </div>
            </div>
          </div>
        ))}
      </div>
         
        </div>
      </div>
      <Footer />
    </div>
    </div>
  );
};

export default BlogSingleDetail;
