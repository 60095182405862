import React from 'react'
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Button, Carousel } from "rsuite";

const gallery = () => {
  return (
    <div>
        <Header/>
        <div className="inner-banner">
        <div className="banner-content">
          <h1>Our Gallery</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" />
          </div>

          {/* <div>
            <img src="/images/2.jpg" />
          </div>

          <div>
            <img src="/images/3.jpg" />
          </div>

          <div>
            <img src="/images/4.jpg" />
          </div>

          <div>
            <img src="/images/5.jpg" />
          </div> */}
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
      <h1>Our Gallery</h1>
          <p>
          Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
      </div>
      <div className='gallery-section'>
        <div className='container'>
            <div className='heading'>
                <h2 className='text-center'>Our Gallery</h2>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/1.jpeg' height="500px"/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/2.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/3.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/4.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/5.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/6.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/7.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/8.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/9.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/10.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/11.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/12.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/13.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/14.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/15.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/16.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/17.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/18.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/19.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/20.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/21.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/22.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/23.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/24.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/25.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/26.jpeg'/>
                    </div>
                </div>
               
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/28.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/29.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/30.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/31.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/32.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/33.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/34.jpeg'/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='gallery-box'>
                        <img src='images/35.jpeg'/>
                    </div>
                </div>
                
            </div>
        </div>
      </div>
        <Footer/>
    </div>
  )
}

export default gallery