import React from "react";
import { Button, Carousel } from "rsuite";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Slider from "react-slick";
import { FiLifeBuoy, FiLoader } from "react-icons/fi";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa6";


const settings = {
  dots: true,
  infinite: false,
  speed: 10000,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const About = () => {
  return (
    <div>
      <Header />
      <div className="inner-banner">
        <div className="banner-content">
          <h1>About Us</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" />
          </div>

          {/* <div>
            <img src="/images/2.jpg" />
          </div>

          <div>
            <img src="/images/3.jpg" />
          </div>

          <div>
            <img src="/images/4.jpg" />
          </div>

          <div>
            <img src="/images/5.jpg" />
          </div> */}
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
      <h1>About Us</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
      </div>

      <div className="milestones-section">
        <div className="container">
          <div className="heading">
            <h2 className="text-center">Milestones</h2>
          </div>
          <div className="row mt-5">
            <div className="top-line">
              <div className="d-1"></div>
              <div className="d-2"></div>
            </div>
            <Slider {...settings}>
              <div className="p-section">
                <div className="line"></div>
                <div className="project-box">
                  <div className="bg-new">
                    <h3>2006</h3>
                    <p>
                      Asset Homes incorporated under the Indian Companies Act,
                      1956 and launches Projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-section">
                <div className="line"></div>
                <div className="project-box">
                  <div className="bg-new">
                    <h3>2006</h3>
                    <p>
                      Asset Homes incorporated under the Indian Companies Act,
                      1956 and launches Projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-section">
                <div className="line"></div>
                <div className="project-box">
                  <div className="bg-new">
                    <h3>2006</h3>
                    <p>
                      Asset Homes incorporated under the Indian Companies Act,
                      1956 and launches Projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-section">
                <div className="line"></div>
                <div className="project-box">
                  <div className="bg-new">
                    <h3>2006</h3>
                    <p>
                      Asset Homes incorporated under the Indian Companies Act,
                      1956 and launches Projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-section">
                <div className="line"></div>
                <div className="project-box">
                  <div className="bg-new">
                    <h3>2006</h3>
                    <p>
                      Asset Homes incorporated under the Indian Companies Act,
                      1956 and launches Projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-section">
                <div className="line"></div>
                <div className="project-box">
                  <div className="bg-new">
                    <h3>2006</h3>
                    <p>
                      Asset Homes incorporated under the Indian Companies Act,
                      1956 and launches Projects
                    </p>
                  </div>
                </div>
              </div>


             


            
            </Slider>
          </div>
        </div>
      </div>

      <div className="mission-section">
        <div className="container">
          <div className="heading mb-4">
            <h2 className="text-light">Our Vision & Mission</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 mb-3">
              <div className="missioni-inner">
                <FiLifeBuoy />
                <h3>Our Vision</h3>
                <p>
                  Asset Homes aims to become a responsible builder in every
                  sense of the competitive market by creating the most reliable
                  real estate brand.
                </p>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="missioni-inner">
                <FiLoader />
                <h3>Our Mission</h3>
                <p>
                  Asset Homes is committed to building innovative, high-quality,
                  functional, sustainable, and value-for-money homes, backed by
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="message-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="message-left">
                <img src="images/message.png" />
              </div>
            </div>
            <div className="col-md-7" style={{ paddingLeft: "50px" }}>
              <div className="message-right">
                <div className="heading">
                  <h2>MD's Message</h2>
                  <h3>Client Name</h3>
                  <h4>Founder & Managing Director</h4>
                  <BiSolidQuoteAltRight />

                  <p>
                    ”As a company, we always endeavour to build value for our
                    stakeholders, be it our customers, employees, investors, or
                    the environment. Our commitment to customers is strongly
                    represented in our unique philosophy of 'Beyond Square
                    Feet'. We have pioneered many customer-centric initiatives
                    to build ever-memorable and delightful experiences for
                    customers. This is what has helped us evolve into India's
                    first life cycle builder. In the future also, as a conscious
                    brand, we will nurture a strong culture of responsibility
                    and a relentless passion for excellence at Asset Homes. We
                    mean it when we say 'Responsibly Yours'."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="director">
        <div className="container">
          <div className="heading">
            <h2 className="text-light text-center">Directors</h2>
          </div>
          <div className="row mt-5">
          <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team1.png" />
                <h3>N. Mohanan</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team2.png" />
                <h3>Dr. Hassan Kunhi</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team3.png" />
                <h3>Mr. C.V. Rappai</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team4.png" />
                <h3>Mr. Gokul Pillai</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
          </div>
          <div
            className="btn-director text-center mt-5"
            style={{
              borderBottom: "1px solid #2B2B2B",
              paddingBottom: "100px",
            }}
          >
            {" "}
            <Button color="red" appearance="primary">
              Show More
            </Button>
          </div>
        </div>
      </div>

      <div className="director team">
        <div className="container">
          <div className="heading">
            <h2 className="text-light text-center">Team</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team1.png" />
                <h3>N. Mohanan</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team2.png" />
                <h3>Dr. Hassan Kunhi</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team3.png" />
                <h3>Mr. C.V. Rappai</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="director-inner">
                <img src="images/team4.png" />
                <h3>Mr. Gokul Pillai</h3>
                <p>Director</p>
                <a href="#">
                  <span> Read More</span>
                  <FaLongArrowAltRight />
                </a>
              </div>
            </div>
          </div>
          <div className="btn-director text-center mt-5">
            {" "}
            <Button color="red" appearance="primary">
              Show More
            </Button>
          </div>
        </div>
      </div>

      <div className="awards">
        <div className="container-fluid">
          <div className="heading text-center">
            <h2 className="mb-3">Awards</h2>
            <p>
              Proof of our hard work, perseverance, and success is what our
              award section reflects! We have been <br/> accredited and acknowledged
              for numerous milestones.
            </p>
          </div>
          <div className="row mt-5">
         <div className="col-md-3 p-0">
         <div className="awards-inner  d-color">
            <img src="images/a1.png"/>
            <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
         <div className="col-md-3 p-0">
         <div className="awards-inner">
            <img src="images/a1.png"/>
            <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
         <div className="col-md-3 p-0">
         <div className="awards-inner  d-color">
            <img src="images/a1.png"/>
            <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
         <div className="col-md-3 p-0">
         <div className="awards-inner ">
            <img src="images/a1.png"/>
            <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
         <div className="col-md-3 p-0">
         <div className="awards-inner">
            <img src="images/a1.png"/>
            <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
         <div className="col-md-3 p-0">
         <div className="awards-inner  d-color">
            <img src="images/a1.png"/>
            <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
         <div className="col-md-3 p-0">
         <div className="awards-inner">
            <img src="images/a1.png"/>
            <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
         <div className="col-md-3 p-0">
         <div className="awards-inner d-color">
            <img src="images/a1.png"/>
           <p className="mt-3">Proof of our hard work, perseverance, and success</p>
          </div>
         </div>
          </div>
          <div className="pt-5 pb-5 text-center">
        
          <Button color="red" appearance="primary">
              Show More
            </Button>
            
          </div>
        </div>
      </div>

<div className="report-section">
  <div className="container">
    <div className="heading">
      <h2 className="text-center text-light">Hurun Report</h2>
    </div>
    <div className="row mt-5">
      <div className="col-md-3 mb-4">
        <div className="report-inner">
          <p>Hurun Report</p>
          <h3>2024</h3>
          <div className="d-flex align-items-center justify-content-between">
          <FaRegFilePdf />
            <Button color="red" appearance="primary">
            View Report
            </Button>
          </div>
        </div>
      </div>
      <div className="col-md-3 mb-4">
        <div className="report-inner">
          <p>Hurun Report</p>
          <h3>2024</h3>
          <div className="d-flex align-items-center justify-content-between">
          <FaRegFilePdf />
            <Button color="red" appearance="primary">
            View Report
            </Button>
          </div>
        </div>
      </div>
      <div className="col-md-3 mb-4">
        <div className="report-inner">
          <p>Hurun Report</p>
          <h3>2024</h3>
          <div className="d-flex align-items-center justify-content-between">
          <FaRegFilePdf />
            <Button color="red" appearance="primary">
            View Report
            </Button>
          </div>
        </div>
      </div>
      <div className="col-md-3 mb-4">
        <div className="report-inner">
          <p>Hurun Report</p>
          <h3>2024</h3>
          <div className="d-flex align-items-center justify-content-between">
          <FaRegFilePdf />
            <Button color="red" appearance="primary">
            View Report
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<Footer/>


      
    </div>
  );
};

export default About;
