import React, { useEffect, useState } from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Carousel } from "rsuite";
import { Link, useNavigate } from "react-router-dom";
import api, { imageUrl } from "./lib/api";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Button from 'rsuite/Button';

const Project = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { data: projectData, isLoading: ipLoadingState } = useQuery(
    ["fetch-project-data"],
    () => api.get("project/fetch-project-data").then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  // Automatically refetch data when the location changes
  useEffect(() => {
    if (projectData) {
      // This useEffect will trigger re-render when location state changes
    }
  }, [location, projectData]);

  if (ipLoadingState) return <div>Loading...</div>;


  return (
    <div>
      <Header />
      <div className="inner-banner">
        <div className="banner-content">
          <h1>
            {location.state?.projecttype
              ? location.state?.projecttype
              : "All Project"}
          </h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" />
          </div>

          {/* <div>
            <img src="/images/2.jpg" />
          </div>

          <div>
            <img src="/images/3.jpg" />
          </div>

          <div>
            <img src="/images/4.jpg" />
          </div>

          <div>
            <img src="/images/5.jpg" />
          </div> */}
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
        <h1>
          {location.state?.projecttype
            ? location.state?.projecttype
            : "All Project"}
        </h1>
        <p>
          Being one of the top real estate home builders in Kerala, we bring you
          a new array of apartments, flats, and villas that match your
          requirements and <br /> complement your lifestyle.
        </p>
      </div>

      <div className="projects" data-aos="fade-up" data-aos-duration="1500">
      <div className="container">
        <div className="heading mb-5">
          <h2 style={{ marginLeft: "35px" }}>
            {state?.id === 1 ? "Ongoing Projects" : state?.id === 2 ? "Completed Projects" : "All Projects"}
          </h2>
        </div>
        <div className="row mt-5">
          {projectData
            ?.filter((item) => !state?.id || item.project_type_id === state.id)
            ?.map((item) => (
              <div className="col-md-4 mb-5" key={item.id}>
                <div
                  onClick={() =>
                    navigate("/Projectdetails", { state: { projectDetails: item } })
                  }
                >
                  <div className="project-box">
                    <img src={`${imageUrl}images/${item?.image}`} alt={item.name} />
                    <h3>{item.name}</h3>
                    <div>{item.project_type}</div>
                    <div>{item.project_category}</div>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <Button
            color="cyan"
            appearance="primary"
            onClick={() =>
              navigate("/project", {
                state: { projecttype: "View All Projects", id: null },
              })
            }
          >
            View All
          </Button>
        </div>
      </div>
    </div>
      <Footer />
    </div>
  );
};

export default Project;
