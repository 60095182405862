import React, { useEffect, useState } from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "rsuite";
import Form from "react-bootstrap/Form";
import { Button } from "rsuite";
import Slider from "react-slick";
import { RiDoubleQuotesR } from "react-icons/ri";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { useQuery, useMutation } from "react-query";
import api, { imageUrl } from "./lib/api";
import DOMPurify from "dompurify";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  initialSlide: 0,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsongoing = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  initialSlide: 0,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings2 = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  initialSlide: 0,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings3 = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  initialSlide: 0,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Home = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [searchDataList, setSearchDataList] = useState([]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const { mutate: searchData, isLoading: searchDataLoading } = useMutation(
    ["searchData"],
    async (body) => await api.post(`project/searchData`, body),
    {
      onSuccess: (res) => {
        if (res.data.data.length > 0) {
          setSearchDataList(res.data.data);
        }
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  
  useEffect(() => {
    searchData({ query: searchQuery });
  }, [searchQuery]);
  
const { data: projectData, isLoading: projectLoading, error: projectError } = useQuery(
  ["fetch-project-data"],
  () => api.get("project/fetch-project-data").then((res) => res.data?.data || [])
);

// Ensure projectData is always an array
const completedProjects = projectData?.filter(project => project.project_type_id === 2) || [];
const ongoingProjects = projectData?.filter(project => project.project_type_id === 1) || [];


  // Slider Image Query
const { data: sliderImages, isLoading: sliderLoading, error: sliderError } = useQuery(
  ["slider-images"],
  () => api.get("/sliderimage").then((res) => res.data.data)
);

// Blog Query
const { data: blogData, isLoading: blogLoading, error: blogError } = useQuery(
  ["fetch-blogs"],
  () => api.get("/blog/blog").then((res) => res.data.data)
);

// Rendering Logic
if (sliderLoading || blogLoading) return <div>Loading...</div>;
if (sliderError) return <div>Error fetching slider images: {sliderError.message}</div>;
if (blogError) return <div>Error fetching blogs: {blogError.message}</div>;
  

  console.log(project);
  console.log(project?.filter((i) => i.project_type_id == 1));

  return (
    <div>
      <Header />
      <div className="banner">
        <div className="banner-content">
          <h1>Welcome To Deepak Home Builds</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
          <div className="banner-search-bar">
            <Button appearance="primary">Search</Button>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder="Search...."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                <ul className="searchbox">
                  {searchDataList?.map((item, index) => (
                    <li
                      key={index}
                      className="searchStyle"
                      onClick={() =>
                        navigate("/Projectdetails", {
                          state: { projectDetails: item },
                        })
                      }
                    >
                      <div className="searchExpText">{item.name}</div>

                      <img
                        className="searchImg"
                        width={50}
                        height={50}
                        src={`${imageUrl}images/${item?.image}`}
                      />
                    </li>
                  ))}
                </ul>
              </Form.Group>
            </Form>
          </div>
        </div>
        <Carousel autoplay className="custom-slider">
        {sliderImages.length > 0 ? (
        sliderImages.map((image) => (
          <div key={image.id}>
            <img
              src={`${imageUrl}images/${image.image}`} // Use image URL with path
              alt={`Slider ${image.id}`}
            />
          </div>
        ))
      ) : (
        <div>No slider images available</div>
      )}
        </Carousel>
      </div>

      <div className="mobile-banner">
        <div className="mobile-banner-inner">
          <div className="banner-content">
            <h1>Welcome To Deepak Home Builds</h1>
            <p>
              Being one of the top real estate home builders in Kerala, we bring
              you a new array of apartments, flats, and villas that match your
              requirements and complement your lifestyle.
            </p>
            <div className="banner-search-bar">
              <Button appearance="primary">Search</Button>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control type="email" placeholder="Search...." />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="projects" data-aos="fade-up" data-aos-duration="1500">
      <div className="container">
            <div className="heading mb-5">
              <h2 style={{ marginLeft: "35px" }}>Ongoing Projects</h2>
            </div>
            <div className="slider-container pt-4">
              <Slider {...settings}>
                {ongoingProjects?.map((item) => (
                  <div
                    key={item.id}
                    onClick={() =>
                      navigate("/Projectdetails", {
                        state: { projectDetails: item },
                      })
                    }
                  >
                    <div className="project-box">
                      <img src={`${imageUrl}images/${item?.image}`} alt={item.name} />
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="text-center mt-5">
                <Button
                  color="cyan"
                  appearance="primary"
                  onClick={() =>
                    navigate("/project", {
                      state: { projecttype: "Ongoing Projects", id: 1 },
                    })
                  }
                >
                  View All
                </Button>
              </div>
            </div>
          </div>

      </div>

      <div className="projects" data-aos="fade-up" data-aos-duration="1500">
      <div className="container">
            <div className="heading mb-5">
              <h2 style={{ marginLeft: "35px" }}>Completed Projects</h2>
            </div>
            <div className="slider-container pt-4">
              <Slider {...settings}>
                {completedProjects?.map((item) => (
                  <div
                    key={item.id}
                    onClick={() =>
                      navigate("/Projectdetails", {
                        state: { projectDetails: item },
                      })
                    }
                  >
                    <div className="project-box">
                      <img src={`${imageUrl}images/${item?.image}`} alt={item.name} />
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="text-center mt-5">
            <Button
                  color="cyan"
                  appearance="primary"
                  onClick={() =>
                    navigate("/project", {
                      state: { projecttype: "Completed Projects", id: 2 },
                    })
                  }
                >
                  View All
                </Button>
            </div>
          </div>
      </div>

      <div className="projects" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading mb-5">
            <h2 style={{ marginLeft: "35px" }}>Our Gallery</h2>
          </div>
          <div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="project-box w-100">
                  <img src="/images/project2.jpg" />
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="project-box w-100">
                  <img src="/images/project2.jpg" />
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="project-box w-100">
                  <img src="/images/project2.jpg" />
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              <Button color="cyan" appearance="primary">
                <Link to="/gallery">View All</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="team-section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading">
            <h2 className="mb-5" data-aos="fade-up" data-aos-duration="1500">
              Our Team
            </h2>
          </div>

          <div className="slider-container pt-4">
            <Slider {...settings2}>
              <div className="team-box">
                <div className="img-box">
                  <img src="/images/team.jpg" />
                </div>
                <div className="content-box">
                  <h3>Sachin Singh</h3>
                  <h4>Civil Engineer</h4>
                  <p>
                    {" "}
                    Being one of the top real estate home builders in Kerala, we
                    bring you a new array of apartments, flats, and villas that
                    match your
                    <br />
                    requirements and complement your lifestyle.
                  </p>
                  <div className="links">
                    <ul className="d-flex m-0 p-0">
                      <li>
                        <FaFacebookF />
                      </li>
                      <li>
                        <FaInstagram />
                      </li>
                      <li>
                        <FaTwitter />
                      </li>
                      <li>
                        <FaLinkedinIn />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-box">
                <div className="img-box">
                  <img src="/images/team.jpg" />
                </div>
                <div className="content-box">
                  <h3>Sachin Singh</h3>
                  <h4>Civil Engineer</h4>
                  <p>
                    {" "}
                    Being one of the top real estate home builders in Kerala, we
                    bring you a new array of apartments, flats, and villas that
                    match your
                    <br />
                    requirements and complement your lifestyle.
                  </p>
                  <div className="links">
                    <ul className="d-flex m-0 p-0">
                      <li>
                        <FaFacebookF />
                      </li>
                      <li>
                        <FaInstagram />
                      </li>
                      <li>
                        <FaTwitter />
                      </li>
                      <li>
                        <FaLinkedinIn />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-box">
                <div className="img-box">
                  <img src="/images/team.jpg" />
                </div>
                <div className="content-box">
                  <h3>Sachin Singh</h3>
                  <h4>Civil Engineer</h4>
                  <p>
                    {" "}
                    Being one of the top real estate home builders in Kerala, we
                    bring you a new array of apartments, flats, and villas that
                    match your
                    <br />
                    requirements and complement your lifestyle.
                  </p>
                  <div className="links">
                    <ul className="d-flex m-0 p-0">
                      <li>
                        <FaFacebookF />
                      </li>
                      <li>
                        <FaInstagram />
                      </li>
                      <li>
                        <FaTwitter />
                      </li>
                      <li>
                        <FaLinkedinIn />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-box">
                <div className="img-box">
                  <img src="/images/team.jpg" />
                </div>
                <div className="content-box">
                  <h3>Sachin Singh</h3>
                  <h4>Civil Engineer</h4>
                  <p>
                    {" "}
                    Being one of the top real estate home builders in Kerala, we
                    bring you a new array of apartments, flats, and villas that
                    match your
                    <br />
                    requirements and complement your lifestyle.
                  </p>
                  <div className="links">
                    <ul className="d-flex m-0 p-0">
                      <li>
                        <FaFacebookF />
                      </li>
                      <li>
                        <FaInstagram />
                      </li>
                      <li>
                        <FaTwitter />
                      </li>
                      <li>
                        <FaLinkedinIn />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-box">
                <div className="img-box">
                  <img src="/images/team.jpg" />
                </div>
                <div className="content-box">
                  <h3>Sachin Singh</h3>
                  <h4>Civil Engineer</h4>
                  <p>
                    {" "}
                    Being one of the top real estate home builders in Kerala, we
                    bring you a new array of apartments, flats, and villas that
                    match your
                    <br />
                    requirements and complement your lifestyle.
                  </p>
                  <div className="links">
                    <ul className="d-flex m-0 p-0">
                      <li>
                        <FaFacebookF />
                      </li>
                      <li>
                        <FaInstagram />
                      </li>
                      <li>
                        <FaTwitter />
                      </li>
                      <li>
                        <FaLinkedinIn />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div
        className="testimonial-section"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="testimonial-inner">
          <div className="container">
            <div className="heading">
              <h2
                className="text-light text-center"
                style={{ marginLeft: "16px" }}
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                What Out Clients Say
              </h2>
            </div>
            <Slider {...settings3}>
              <div className="testimonial-box">
                <div className="left">
                  <img src="/images/testimonial-image.jpg" />
                </div>
                <div className="right">
                  <h3>Robin Singh</h3>
                  <i>
                    <RiDoubleQuotesR />
                  </i>
                  <p>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder text commonly used to demonstrate the visual
                    form of a document or a typeface without relying on
                    meaningful content. Lorem ipsum may be used as a placeholder
                    before the final copy is available.
                  </p>
                </div>
              </div>
              <div className="testimonial-box">
                <div className="left">
                  <img src="/images/testimonial-image.jpg" />
                </div>
                <div className="right">
                  <h3>Robin Singh</h3>
                  <i>
                    <RiDoubleQuotesR />
                  </i>
                  <p>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder text commonly used to demonstrate the visual
                    form of a document or a typeface without relying on
                    meaningful content. Lorem ipsum may be used as a placeholder
                    before the final copy is available.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="appointment-section" data-aos="fade-up">
        <div className="appointment-inner">
          <div className="heading">
            <h2
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Book an Appointment
            </h2>
          </div>
          <Form data-aos="fade-up" data-aos-duration="1500">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <lable>Your Name</lable>
              <Form.Control type="email" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <lable>Your Email</lable>
              <Form.Control type="email" />
            </Form.Group>
            <div className="d-grid">
              <lable>Message</lable>
              <textarea></textarea>
            </div>
            <button className="btn btn-dark">Book Now</button>
          </Form>
        </div>
      </div>

      <div className="blog-section">
  <div className="container">
    <div className="heading">
      <h2 data-aos="fade-up" data-aos-duration="1500">Latest News and Blogs</h2>
    </div>
    <div className="row mt-5">
      {blogData?.slice(0, 2).length > 0 ? (
        blogData.slice(0, 2).map((blog) => (
          <React.Fragment key={blog.id}>
            <div
              className="col-md-4 mb-5"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="blog-img">
                <img
                  src={`${imageUrl}images/${blog.image}`} // Dynamic image URL
                  alt={blog.title}
                />
              </div>
            </div>
            <div
              className="col-md-8 mb-5"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="blog-content" style={{ paddingLeft: "150px" }}>
                <h3>{blog.title}</h3>
                <div
                  className="col-md-12"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(blog?.content),
                  }}
                ></div> {/* Short preview */}
                <button className="btn btn-dark">
                  <Link to={`/blog/${blog.id}`}>View More</Link>
                </button>
              </div>
            </div>
          </React.Fragment>
        ))
      ) : (
        <p>No blogs available</p>
      )}
    </div>
  </div>
</div>



      <div className="blog-section blog2">
        <div className="container">
          <div className="heading">
            <h2 data-aos="fade-up" data-aos-duration="1500">
              Latest News and Blogs
            </h2>
          </div>
          <div className="row mt-5 align-items-center">
            <div className="col-md-12 mb-3">
              <div className="blog-img">
                <img src="/images/project2.jpg" />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="blog-content">
                <h3>Budget Residences</h3>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </p>
                <button className="btn btn-dark">
                  <Link to="/blog">View More</Link>
                </button>
              </div>
            </div>

            <div className="col-md-12 mb-3 mt-5">
              <div className="blog-img">
                <img src="/images/project2.jpg" />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="blog-content">
                <h3>Budget Residences</h3>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </p>
                <button className="btn btn-dark">
                  <Link to="/blog">View More</Link>
                </button>
              </div>
            </div>

            <div className="col-md-12 mb-3 mt-5">
              <div className="blog-img">
                <img src="/images/project2.jpg" />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="blog-content">
                <h3>Budget Residences</h3>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </p>
                <button className="btn btn-dark">
                  <Link to="/blog">View More</Link>
                </button>
              </div>
            </div>

            <div className="col-md-12 mb-3 mt-5">
              <div className="blog-img">
                <img src="/images/project2.jpg" />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="blog-content">
                <h3>Budget Residences</h3>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </p>
                <button className="btn btn-dark">
                  <Link to="/blog">View More</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="subscribe-section mt-5"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="heading">
                <h3>Subscribe</h3>
              </div>
              <p>Subscribe our newsletter to stay updated every moment</p>
            </div>
            <div className="col-md-6">
              <div className="sub-input">
                <Form.Control type="email" placeholder="Search...." />
                <button className="btn btn-dark">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
