import React from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Carousel } from "rsuite";
import { useLocation } from "react-router-dom";
import { imageUrl } from "./lib/api";

const Projectdetails = () => {
   const location = useLocation();
   const data = location.state?.projectDetails;
   

   console.log("data4444444444",data)

  return (
    <div>
      {" "}
      <Header />
      <div className="inner-banner">
        <div className="banner-content">
          <h1>{data?.project_type_id == 1 ? "Ongoing Projects Details" :  "Upcoming Projects Details"}</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" />
          </div>

          {/* <div>
            <img src="/images/2.jpg" />
          </div>

          <div>
            <img src="/images/3.jpg" />
          </div>

          <div>
            <img src="/images/4.jpg" />
          </div>

          <div>
            <img src="/images/5.jpg" />
          </div> */}
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
      <h1>Ongoing Projects Details</h1>
          <p>
          Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
      </div>
      <div className="project-inner-section">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-md-3 col-6 mb-3">
                 <img src={`${imageUrl}images/${data?.image}`} />
              </div>
             
            </div>
          </div>

          <div className="project-inner-details p-0 mt-4">
            <div className="heading">
              <h3>{data?.name}</h3>
               <div>
                            {data.project_type}
                          </div>
                          <div>
                            {data.project_category}
                          </div>
              <p className="mt-4">
               {data?.description}
              </p>
           
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Projectdetails;
