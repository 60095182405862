import React from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Button, Carousel } from "rsuite";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { IoMail } from "react-icons/io5";

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="inner-banner">
        <div className="banner-content">
          <h1>Contact Us</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" />
          </div>

          {/* <div>
      <img src="/images/2.jpg" />
    </div>

    <div>
      <img src="/images/3.jpg" />
    </div>

    <div>
      <img src="/images/4.jpg" />
    </div>

    <div>
      <img src="/images/5.jpg" />
    </div> */}
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
      <h1>Contact Us</h1>
          <p>
          Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
      </div>
      <div className="contact-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="heading">
                <h2>Contact Informating</h2>
                <div className="contact-details">
                  <ul>
                    <li>
                      <i>
                        <IoLocationSharp />
                      </i>
                      Commercial plot no 3, adjacent to Kriti health care center
                      , sector 63 Gurgaon122011
                    </li>
                    <li>
                      {" "}
                      <i>
                        <IoMail />
                      </i>
                      Example@gmail.com
                    </li>
                    <li>
                      {" "}
                      <i>
                        <IoIosCall />
                      </i>
                      Sagar solanki - 9821969700
                    </li>
                    <li>
                      {" "}
                      <i>
                        <IoIosCall />
                      </i>
                      Rajiv Sehgal - 9821919274
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <img src="images/contact.jpg" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
