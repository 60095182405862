import React from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Button, Carousel } from "rsuite";
import { RiDoubleQuotesR } from "react-icons/ri";

const Testimonial = () => {
  return (
    <div>
      <Header />
      <div className="inner-banner">
        <div className="banner-content">
          <h1>Testimonials</h1>
          <p>
            Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
        </div>
        <Carousel autoplay className="custom-slider">
          <div>
            <img src="/images/1.jpg" />
          </div>

          {/* <div>
            <img src="/images/2.jpg" />
          </div>

          <div>
            <img src="/images/3.jpg" />
          </div>

          <div>
            <img src="/images/4.jpg" />
          </div>

          <div>
            <img src="/images/5.jpg" />
          </div> */}
        </Carousel>
      </div>

      <div className="mobile-inner-banner">
      <h1>Testimonials</h1>
          <p>
          Being one of the top real estate home builders in Kerala, we bring
            you a new array of apartments, flats, and villas that match your
            requirements and <br /> complement your lifestyle.
          </p>
      </div>
      <div className="testimonial-inner-section">
        <div className="container">
          <div className="heading">
            <h2 className="text-center">Testimonial</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="testi-inner">
              <RiDoubleQuotesR />
                <img src="images/testi.jpg"/>
              <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
                <h2>Ribin Singh</h2>
               
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-inner">
              <RiDoubleQuotesR />
                <img src="images/testi.jpg"/>
              <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
                <h2>Ribin Singh</h2>
               
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-inner">
              <RiDoubleQuotesR />
                <img src="images/testi.jpg"/>
              <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
                <h2>Ribin Singh</h2>
               
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-inner">
              <RiDoubleQuotesR />
                <img src="images/testi.jpg"/>
              <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
                <h2>Ribin Singh</h2>
               
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-inner">
              <RiDoubleQuotesR />
                <img src="images/testi.jpg"/>
              <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
                <h2>Ribin Singh</h2>
               
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-inner">
              <RiDoubleQuotesR />
                <img src="images/testi.jpg"/>
              <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
                <h2>Ribin Singh</h2>
               
              </div>
            </div>
          
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Testimonial;
