import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import 'rsuite/dist/rsuite.min.css';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import About from "./About";
import Project from "./Project";
import Projectdetails from "./Projectdetails";
import Testimonial from "./Testimonial";
import Gallery from "./Gallery";
import Blog from "./Blog";
import BlogDetail from "./BlogDetails";
import Contact from "./Contact";
import Home from "./Home";
import BlogSingleDetail from "./BlogSingleDetail";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>
      <Router>
        <Routes>
          <Route element={<Home />} path="/" />
        </Routes>
        <Routes>
          <Route element={<About />} path="/about" />
          <Route element={<Project />} path="/project" />
          <Route element={<Projectdetails />} path="/projectdetails" />
          <Route element={<Testimonial />} path="/testimonial" />
          <Route element={<Gallery />} path="/gallery" />
          {/* <Route element={<Blog />} path="/blog" /> */}
          <Route element={<Contact />} path="/contact" />
          <Route element={<BlogDetail />} path="/blog" />
          <Route element={<BlogSingleDetail />} path="/blog/:id" />
        </Routes>
      </Router>
    </>
  );

  // return (
  //   <Routes>
  //     <Route element={<Home />} path="/" />
  //     <Route element={<About />} path="/about" />
  //     <Route element={<Project />} path="/project" />
  //     <Route element={<Projectdetails />} path="/projectdetails" />
  //     <Route element={<Testimonial />} path="/testimonial" />
  //     <Route element={<Gallery />} path="/gallery" />
  //     <Route element={<Blog />} path="/blog" />
  //     <Route element={<Contact />} path="/contact" />
  //     <Route element={<BlogDetail />} path="/blogdetail" />
  //     <Route element={<BlogSingleDetail />} path="/blog/:id" />
  //   </Routes>
  // );
}

export default App;
